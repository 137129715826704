export const TOKEN_LIST = `${window.location.origin}/data/token-list.json`
export const STABLE_TOKEN_LIST = `${window.location.origin}/data/stable-token-list.json`

// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = TOKEN_LIST

export const DEFAULT_LIST_OF_LISTS: string[] = [
    DEFAULT_TOKEN_LIST_URL,
    STABLE_TOKEN_LIST
]

import { JSBI, Percent, Token, WETH as UWETH } from 'zksdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainId } from './chainId';
import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import LootChain from 'assets/svg/loot.svg'
import BaseChain from 'assets/svg/base.svg'
import TaikoChain from 'assets/svg/taiko.svg'
import BlastChain from 'assets/images/blast.png'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const ROUTER_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.LOOT]: '0xcdCe9e57CB1cA1e7EA129bC211e8F3741C4dC898',
  [ChainId.BASE]: '0xD55a4d54f39baF26da2F3EE7bE9a6388c15F9831',
  [ChainId.TAIKO]: '0xDA6b3B31177a07E157c170A31cF9C85e64f35d27',
  [ChainId.BLAST_SEPOLIA]: '0xcdCe9e57CB1cA1e7EA129bC211e8F3741C4dC898'
}
export const FACTORY_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.LOOT]: '0xDA6b3B31177a07E157c170A31cF9C85e64f35d27',
  [ChainId.BASE]: '0x539db2B4FE8016DB2594d7CfbeAb4d2B730b723E',
  [ChainId.TAIKO]: '0x7D40C46CaD2C6Ade6440008f74dFb66b3FC947E4',
  [ChainId.BLAST_SEPOLIA]: '0xDA6b3B31177a07E157c170A31cF9C85e64f35d27'
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const USDT = {
  [ChainId.LOOT]: new Token(ChainId.LOOT, '0x99880520F81931d89330181271e82444659eDB3E', 6, 'USDT', 'Tether USD'),
}
export const DAI = {
  [ChainId.LOOT]: new Token(ChainId.LOOT, '0x238976DC5F6cfa166A79A82FD526e9B8397a5046', 6, 'DAI', 'DAI'),
  [ChainId.BASE]: new Token(ChainId.BASE, '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb', 18, 'DAI', 'DAI'),
}
export const WAMPL = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x489fe42C267fe0366B16b0c39e7AEEf977E841eF', 18, 'WAMPL', 'Wrapped AMPL'),
}
export const SEAM = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x1C7a460413dD4e964f96D8dFC56E7223cE88CD85', 18, 'SEAM', 'Seamless'),
}
export const USDC: any = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', 6, 'USDC', 'USD Coin'),
  [ChainId.TAIKO]: new Token(ChainId.TAIKO, '0x07d83526730c7438048D55A4fc0b850e2aaB6f0b', 6, 'USDC', 'USD Coin'),
}
export const USDbc: any = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', 6, 'USDbC', 'USD Base Coin'),
}
export const Rebase: any = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x3421cc14F0e3822Cf3B73C3a4BEC2A1023b8d9Cf', 6, 'Rebase', 'Rebase'),
}
export const SOFI = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x703D57164CA270b0B330A87FD159CfEF1490c0a5', 18, 'SOFI', 'RaiFinance'),
  [ChainId.BLAST_SEPOLIA]: new Token(ChainId.BLAST_SEPOLIA, '0x12BF894c47D9246e92F58d6AE22E1A32fd6D306E', 18, 'SOFI', 'RaiFinance'),
}

export const WAGLD = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x19e9338be738c7e51b2126A5e7B6A4c1ecB03DC2', 18, 'WAGLD', 'Wrapped AGLD'),
}
export const cbETH = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22', 18, 'cbETH', 'Coinbase Wrapped Staked ETH'),
}

export const TKO = {
  [ChainId.TAIKO]: new Token(ChainId.TAIKO, '0xA9d23408b9bA935c230493c40C73824Df71A0975', 18, 'TKO', 'Taiko'),
}
export const AIINU = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x8853F0c059C27527d33D02378E5E4F6d5afB574a', 18, 'AIINU', 'AI INU'),
}
// export const zkSTART = new Token(ChainId.ZKSYNCTEST, '0x880F03cA84e6Cf0D0871c9818A2981DEBabA22b3', 18, 'tSPACE', 'tSPACE')
// export const zkUSDC = new Token(ChainId.ZKSYNCTEST, '0x0faF6df7054946141266420b43783387A78d82A9', 6,  'USDC', 'USDC')
export const zkwBTC = new Token(ChainId.ZKSYNCTEST, '0x0BfcE1D53451B4a8175DD94e6e029F7d8a701e9c', 8, 'wBTC', 'wBTC')
export const zkLINK = new Token(ChainId.ZKSYNCTEST, '0x40609141Db628BeEE3BfAB8034Fc2D8278D0Cc78', 18,  'LINK', 'LINK')
export const zkDAI = new Token(ChainId.ZKSYNCTEST, '0x3e7676937A7E96CFB7616f255b9AD9FF47363D4b', 18,  'DAI', 'DAI')
export const zkSOFI = new Token(ChainId.ZKSYNCTEST, '0x4cbBd570C372847F0A1E1147bd8bD2bA7C558bf6', 18,  'SOFI', 'RaiFinance')

export const zkUSDC = new Token(ChainId.ZKSYNC, '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4', 6,  'USDC', 'USDC')

export const AGLD = {
  [ChainId.LOOT]: new Token(ChainId.LOOT, '0x4fa214c9e33d481996bec77c443245fbaee85670', 18, 'AGLD', 'AGLD'),
}
export const PRED = {
  [ChainId.PZ]: new Token(ChainId.PZ, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.PZT]: new Token(ChainId.PZT, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.BASE_GOERLI]: new Token(ChainId.BASE_GOERLI, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.BASE]: new Token(ChainId.BASE, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.ZKSYNCTEST]: new Token(ChainId.ZKSYNCTEST, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.ZKSYNC]: new Token(ChainId.ZKSYNC, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.LOOT]: new Token(ChainId.LOOT, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.TAIKO]: new Token(ChainId.TAIKO, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
  [ChainId.BLAST_SEPOLIA]: new Token(ChainId.BLAST_SEPOLIA, '0x567E3dfdD1bd5A57525b2b82dEA8FB219f3A3FBc', 18, 'PRED', 'PRED'),
}


export const WETH = {
  ...UWETH,
  [ChainId.ZKSYNC]: new Token(ChainId.ZKSYNC, '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91', 18,  'WETH', 'Wrapped ETH'),
  [ChainId.ZKSYNCTEST]: new Token(ChainId.ZKSYNCTEST, "0x8a144308792a23AadB118286aC0dec646f638908", 18,  'WETH', 'Wrapped ETH'),
  [ChainId.LOOT]: new Token(ChainId.LOOT, "0x19e9338be738c7e51b2126A5e7B6A4c1ecB03DC2", 18,  'AGLD', 'Wrapped AGLD'),
  [ChainId.BASE]: new Token(ChainId.BASE, "0x4200000000000000000000000000000000000006", 18,  'WETH', 'Wrapped ETH'),
  [ChainId.TAIKO]: new Token(ChainId.TAIKO, "0xA51894664A773981C6C112C43ce576f315d5b1B6", 18,  'WETH', 'Wrapped ETH'),
  [ChainId.BLAST_SEPOLIA]: new Token(ChainId.BLAST_SEPOLIA, "0x7D40C46CaD2C6Ade6440008f74dFb66b3FC947E4", 18,  'WETH', 'Wrapped ETH'),
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.PZ]: [WETH[ChainId.PZ]],
  [ChainId.PZT]:[WETH[ChainId.PZT]],
  [ChainId.BASE]: [WETH[ChainId.BASE]],
  [ChainId.BASE_GOERLI]: [WETH[ChainId.BASE_GOERLI]],
  [ChainId.POLYGON]: [WETH[ChainId.POLYGON]],
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.ZKSYNCTEST]: [WETH[ChainId.ZKSYNCTEST]],
  [ChainId.ZKSYNC]: [WETH[ChainId.ZKSYNC]],
  [ChainId.LOOT]: [WETH[ChainId.LOOT]],
  [ChainId.TAIKO]: [WETH[ChainId.TAIKO]],
  [ChainId.BLAST_SEPOLIA]: [WETH[ChainId.BLAST_SEPOLIA]]
}
export const GAS_TOKEN_SYMBOL: { [chainId in ChainId]: string }  = {
  [ChainId.MAINNET]: 'ETH',
  [ChainId.ROPSTEN]:  'ETH',
  [ChainId.RINKEBY]: 'ETH',
  [ChainId.GÖRLI]:  'ETH',
  [ChainId.KOVAN]:  'ETH',
  [ChainId.POLYGON]: 'MATIC',
  [ChainId.BASE]: 'ETH',
  [ChainId.BASE_GOERLI]:  'ETH',
  [ChainId.PZT]: 'ETH',
  [ChainId.PZ]: 'ETH',
  [ChainId.ZKSYNCTEST]: 'ETH',
  [ChainId.ZKSYNC]:  'ETH',
  [ChainId.LOOT]: 'AGLD',
  [ChainId.TAIKO]: 'ETH',
  [ChainId.BLAST_SEPOLIA]: 'ETH'
}

export const DEFAULT_SWAP_PAIRS:{ [chainId in ChainId]?: string[] }  = {
  [ChainId.BASE]: ['ETH', '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'],
  [ChainId.LOOT]: ['AGLD', '0x238976DC5F6cfa166A79A82FD526e9B8397a5046'],
  [ChainId.TAIKO]: ['ETH', '0x07d83526730c7438048D55A4fc0b850e2aaB6f0b'],
  [ChainId.BLAST_SEPOLIA]: ['ETH', '0x12BF894c47D9246e92F58d6AE22E1A32fd6D306E'],
}

export const DEFAULT_STABLE_SWAP_PAIRS:{ [chainId in ChainId]?: string[] }  = {
  [ChainId.BASE]: ['0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca'],
}

export const TSOFI = {
  [ChainId.LOOT]: new Token(ChainId.LOOT, '0xB49fa25978abf9a248b8212Ab4b87277682301c0', 18, 'SOFI', 'SOFI SOFI'),
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.ZKSYNCTEST]: [...WETH_ONLY[ChainId.ZKSYNCTEST]],
  [ChainId.ZKSYNC]: [...WETH_ONLY[ChainId.ZKSYNC]],
  [ChainId.LOOT]: [...WETH_ONLY[ChainId.LOOT]],
  [ChainId.BASE]: []
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.ZKSYNCTEST]: [...WETH_ONLY[ChainId.ZKSYNCTEST], zkSOFI, zkDAI],
  [ChainId.ZKSYNC]: [...WETH_ONLY[ChainId.ZKSYNC], zkUSDC],
  [ChainId.LOOT]: [DAI[ChainId.LOOT]],
  [ChainId.BASE]: [...WETH_ONLY[ChainId.BASE], USDC[ChainId.BASE], USDbc[ChainId.BASE], Rebase[ChainId.BASE], SOFI[ChainId.BASE], AIINU[ChainId.BASE], DAI[ChainId.BASE], SEAM[ChainId.BASE], WAMPL[ChainId.BASE]],
  [ChainId.TAIKO]: [...WETH_ONLY[ChainId.TAIKO], USDC[ChainId.TAIKO], TKO[ChainId.TAIKO]],
  [ChainId.BLAST_SEPOLIA]: [...WETH_ONLY[ChainId.BLAST_SEPOLIA], SOFI[ChainId.BLAST_SEPOLIA]]
}

// used for display in the default list when adding liquidity
export const SUGGESTED_STABLE_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.BASE]: [USDC[ChainId.BASE], USDbc[ChainId.BASE]]
}
// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.ZKSYNCTEST]: [...WETH_ONLY[ChainId.ZKSYNCTEST], zkSOFI, zkDAI],
  [ChainId.ZKSYNC]: [...WETH_ONLY[ChainId.ZKSYNC], zkUSDC],
  [ChainId.BASE]: [...WETH_ONLY[ChainId.BASE], USDbc[ChainId.BASE], SOFI[ChainId.BASE], USDC[ChainId.BASE], WAGLD[ChainId.BASE], cbETH[ChainId.BASE], SEAM[ChainId.BASE] ],
  [ChainId.BLAST_SEPOLIA]: [...WETH_ONLY[ChainId.BLAST_SEPOLIA], SOFI[ChainId.BLAST_SEPOLIA]]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
}

export const DEFAULT_API_URL = process.env.REACT_APP_API_URL || 'https://api.rai.finance'

type ApiURLList = {
  readonly [chainId in ChainId]: string
}

export const APILIST: ApiURLList = {
  [ChainId.MAINNET]: DEFAULT_API_URL + '/eth',
  [ChainId.ROPSTEN]: DEFAULT_API_URL,
  [ChainId.RINKEBY]: DEFAULT_API_URL + '/rinkeby',
  [ChainId.GÖRLI]: DEFAULT_API_URL,
  [ChainId.KOVAN]: DEFAULT_API_URL,
  [ChainId.POLYGON]: DEFAULT_API_URL + '/matic',
  [ChainId.BASE]: DEFAULT_API_URL + '/base',
  [ChainId.BASE_GOERLI]: DEFAULT_API_URL + '/base-goerli',
  [ChainId.PZT]: DEFAULT_API_URL + '/pzt',
  [ChainId.PZ]: DEFAULT_API_URL + '/pz',
  [ChainId.ZKSYNC]:  DEFAULT_API_URL + '/zksync',
  [ChainId.ZKSYNCTEST]: DEFAULT_API_URL + '/zktest',
  [ChainId.LOOT]: DEFAULT_API_URL + '/loot',
  [ChainId.TAIKO]: DEFAULT_API_URL + '/taiko',
  [ChainId.BLAST_SEPOLIA]: DEFAULT_API_URL + '/blast',
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}
export interface BaseChain {
  id: number;
  name: string;
  tokenSymbol: string;
  token: any;
  wrappedTokenSymbol?: string;
  wrappedToken?: any;
  icon: string;
  scanUrl: string;
  scanName: string;
  vmType: string;
  rpcUrl: string;
  blockDelta?: number; // time for producing a new block
  blockDeltaU?: number; // time for producing a new block average
}
const baseURL = "https://rai-static.s3.ap-northeast-1.amazonaws.com/sts/token/"

const initialChains: BaseChain[] = [
  {
      id: ChainId.BASE,
      name: 'Base',
      tokenSymbol: 'ETH',
      token: WETH[ChainId.BASE],
      wrappedTokenSymbol: 'WETH',
      wrappedToken: WETH[ChainId.BASE],
      icon: BaseChain,
      scanUrl: 'https://basescan.org/',
      scanName: 'base scan',
      vmType: 'EVM',
      rpcUrl: 'https://base-mainnet.g.alchemy.com/v2/1a2r_yoKeGEi5H7fRuhstRl2ll0V-30t',
  },{
    id: ChainId.LOOT,
    name: 'Loot Chain',
    tokenSymbol: 'AGLD',
    token: WETH[ChainId.LOOT],
    wrappedTokenSymbol: 'WAGLD',
    wrappedToken:  WETH[ChainId.LOOT],
    icon: LootChain,
    scanUrl: 'https://explorer.lootchain.com/',
    scanName: 'Lootscan',
    vmType: 'EVM',
    rpcUrl: 'https://rpc.lootchain.com/http',
  },{
    id: ChainId.TAIKO,
    name: 'Taiko',
    tokenSymbol: 'ETH',
    token: WETH[ChainId.TAIKO],
    wrappedTokenSymbol: 'WETH',
    wrappedToken:  WETH[ChainId.TAIKO],
    icon: TaikoChain,
    scanUrl: 'https://taikoscan.io/',
    scanName: 'Taikoscan',
    vmType: 'EVM',
    rpcUrl: 'https://rpc.taiko.xyz',
  },
  {
    id: ChainId.BLAST_SEPOLIA,
    name: 'Blast Sepolia',
    tokenSymbol: 'ETH',
    token: WETH[ChainId.BLAST_SEPOLIA],
    wrappedTokenSymbol: 'WETH',
    wrappedToken:  WETH[ChainId.BLAST_SEPOLIA],
    icon: BlastChain,
    scanUrl: ' https://testnet.blastscan.io/',
    scanName: 'BlastSepoliascan',
    vmType: 'EVM',
    rpcUrl: 'https://sepolia.blast.io',
  },
];

export const getChain = (chainId: ChainId) => {
  return initialChains.find((chain) => {
      return chain.id === chainId;
  });
};

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(15)) // .001 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))


export const RAIProxy_contract_address: { [chainId in ChainId]?: string } = {
  [ChainId.BASE]: '0x53BAE026d9a503d46a58aF4b65FCcbb7B904A911',
}

export const Stable_swap_contract_address: { [chainId in ChainId]?: string } = {
  [ChainId.BASE]: '0xc8Ef416b8a09efcBb5ac280a842E015e34dFeE5e',
}


// hide from overview list
export const TOKEN_BLACKLIST = [
  '0x495c7f3a713870f68f8b418b355c085dfdc412c3',
  '0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
  '0xe31debd7abff90b06bca21010dd860d8701fd901',
  '0xfc989fbb6b3024de5ca0144dc23c18a063942ac1',
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76',
  '0x93b2fff814fcaeffb01406e80b4ecd89ca6a021b',

  // rebass tokens
  '0x9ea3b5b4ec044b70375236a281986106457b20ef',
  '0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
  '0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
  '0xfae9c647ad7d89e738aba720acf09af93dc535f7',
  '0x7296368fe9bcb25d3ecc19af13655b907818cc09',
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76':
    'TikTok Inc. has asserted this token is violating its trademarks and therefore is not available.',
}

import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Activity } from 'react-feather'
import numeral  from 'numeral'
import { Area, XAxis, YAxis, ResponsiveContainer, Tooltip, AreaChart, BarChart, Bar } from 'recharts'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { toK, toNiceDate, toNiceDateYear, formattedNum, getTimeframe } from '../../utils'
import { darken } from 'polished'
import moment from 'moment'
import { useMedia, usePrevious } from 'react-use'
import { timeframeOptions } from '../../constants'
// import { useTokenChartData, useTokenPriceData } from '../../contexts/TokenData'
import CandleStickChart from '../CandleChart'
import TradingViewChart, { CHART_TYPES } from '../TradingviewChart'
import DropdownSelect from '../DropdownSelect'
// import LocalLoader from '../LocalLoader'
import { AutoColumn } from '../Column'

const ChartWrapper = styled.div`
  height: 100%;
  min-height: 300px;

  @media screen and (max-width: 600px) {
    min-height: 200px;
  }
`

export const OptionButton = styled.div<{active?: boolean, disabled?: boolean}>`
  font-weight: 500;
  width: fit-content;
  white-space: nowrap;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  background-color: ${({ active, theme }) => active && theme.bg3};
  color: ${({ theme }) => theme.text1};

  :hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
  }
`

const PriceOption = styled(OptionButton)`
  border-radius: 2px;
`

const CHART_VIEW = {
  PRICE: 'Price',
  LINE_PRICE: 'Price (Line)',
}

const DATA_FREQUENCY = {
  DAY: 'DAY',
  HOUR: 'HOUR',
  LINE: 'LINE',
}

const TooltipBox = styled.div`
  border: 1px solid #14B8A6;
  padding: 5px;
  border-radius: 12px;
  p{
    margin: 0
  }
`

const TokenChart = ({ data, color, base }: any) => {
  // settings for the window and candle width
  const [chartFilter, setChartFilter] = useState(CHART_VIEW.PRICE)
  const [frequency, setFrequency] = useState(DATA_FREQUENCY.LINE)

  // const [darkMode] = useDarkModeManager()
  const textColor =  'dark'

  let chartData: any = data.length > 0 ? data?.reverse() : []

  const [timeWindow, setTimeWindow] = useState(timeframeOptions.WEEK)
  const prevWindow = usePrevious(timeWindow)

  // hourly and daily price data based on the current time window
  const hourlyWeek: any = []
  const hourlyMonth: any = []
  const hourlyAll: any = []
  const dailyWeek: any = []
  const dailyMonth: any = []
  const dailyAll: any = []

  // switch to hourly data when switched to week window
  useEffect(() => {
    if (timeWindow === timeframeOptions.WEEK && prevWindow && prevWindow !== timeframeOptions.WEEK) {
      setFrequency(DATA_FREQUENCY.HOUR)
    }
  }, [prevWindow, timeWindow])

  // switch to daily data if switche to month or all time view
  useEffect(() => {
    if (timeWindow === timeframeOptions.MONTH && prevWindow && prevWindow !== timeframeOptions.MONTH) {
      setFrequency(DATA_FREQUENCY.DAY)
    }
    if (timeWindow === timeframeOptions.ALL_TIME && prevWindow && prevWindow !== timeframeOptions.ALL_TIME) {
      setFrequency(DATA_FREQUENCY.DAY)
    }
  }, [prevWindow, timeWindow])

  const below1080 = useMedia('(max-width: 1080px)')
  const below600 = useMedia('(max-width: 600px)')

  let utcStartTime = getTimeframe(timeWindow)
  const domain: any = ['dataMin', 'dataMax']
  const aspect = below1080 ? 60 / 32 : below600 ? 60 / 42 : 60 / 22

  // if(typeof chartData?.filter === "function"){
  //   chartData = chartData?.filter((entry: any) => entry.ts*1000 >= utcStartTime)
  // }

  // update the width on a window resize
  const ref: any = useRef()
  const isClient = typeof window === 'object'
  const [width, setWidth] = useState(ref?.current?.container?.clientWidth)
  const formatToolTip = (chartData: any) => {
    if (!chartData) return ['--', 'No Data Available']
    const {
      payload: { value, ts },
    } = chartData
    const timeString = moment(ts*1000).format('YYYY-MM-DD HH:mm:ss');
    return [timeString, `$${numeral(value).format('0,0.00')}`]
  }
  const renderTooltip = (props: any) => {
    const tooltipData = props.payload?.[0]
    const [label, value] = formatToolTip(tooltipData)

    return <TooltipBox>
      <p>{label}</p>
      <p>Price: {value}</p>
    </TooltipBox>
  }

  useEffect(() => {
    if (!isClient) {
      return
    }
    function handleResize() {
      setWidth(ref?.current?.container?.clientWidth ?? width)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isClient, width]) // Empty array ensures that effect is only run on mount and unmount

  return (
    <ChartWrapper>
        <RowBetween
          align="flex-start"
        >
          <AutoRow gap="10px">
            <RowFixed>
              <OptionButton
                active={chartFilter === CHART_VIEW.PRICE}
                onClick={() => {
                  setChartFilter(CHART_VIEW.PRICE)
                }}
              >
                Price
              </OptionButton>
            </RowFixed>
            {chartFilter === CHART_VIEW.PRICE && (
              <RowFixed>
                <OptionButton
                  active={frequency === DATA_FREQUENCY.LINE}
                  onClick={() => setFrequency(DATA_FREQUENCY.LINE)}
                >
                  <Activity size={14} />
                </OptionButton>
              </RowFixed>
            )}
          </AutoRow>
        </RowBetween>
      {chartFilter === CHART_VIEW.PRICE &&
        <ResponsiveContainer aspect={below1080 ? 60 / 32 : 60 / 28}>
          <AreaChart margin={{ top: 0, right: 10, bottom: 6, left: 0 }} data={chartData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="rgba(20, 184, 166, 0.5)" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="rgba(20, 184, 166, 0)" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <XAxis
              tickLine={false}
              axisLine={false}
              interval="preserveEnd"
              tickMargin={16}
              minTickGap={120}
              tickFormatter={(tick) => moment(tick*1000).format('YYYY-MM-DD')}
              dataKey="ts"
              tick={{ fill: textColor }}
              type={'number'}
              domain={domain}
            />
            <YAxis
              type="number"
              orientation="right"
              tickFormatter={(tick) => '$' + toK(tick)}
              axisLine={false}
              tickLine={false}
              interval="preserveEnd"
              minTickGap={80}
              yAxisId={0}
              tick={{ fill: textColor }}
            />
            <Tooltip
              content={renderTooltip}
            />
            <Area
              type='monotone'
              dataKey='value'
              dot={false}
              stroke="#14B8A6" 
              fillOpacity={1} 
              fill="url(#colorUv)"
              animationEasing='ease'
              animationDuration={800}
            />
          </AreaChart>
        </ResponsiveContainer>
      }
    </ChartWrapper>
  )
}

export default TokenChart

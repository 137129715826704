import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { ChainId } from 'constants/chainId'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '8453')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { 
    [ChainId.MAINNET]: NETWORK_URL ,
    [ChainId.POLYGON]: 'https://polygon-rpc.com/',
    [ChainId.BASE]: 'https://base-mainnet.g.alchemy.com/v2/1a2r_yoKeGEi5H7fRuhstRl2ll0V-30t',
    [ChainId.LOOT]: 'https://rpc.lootchain.com/http',
    [ChainId.BLAST_SEPOLIA]: 'https://sepolia.blast.io',
    [ChainId.TAIKO]: 'https://rpc.katla.taiko.xyz'
  },
  defaultChainId: NETWORK_CHAIN_ID
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [ChainId.BASE, ChainId.LOOT, ChainId.TAIKO, ChainId.BLAST_SEPOLIA]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 280: NETWORK_URL},
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Soswap',
  appLogoUrl:'https://soswap.rai.finance/favicon.png',
  supportedChainIds: [ChainId.BASE, ChainId.LOOT, ChainId.TAIKO, ChainId.BLAST_SEPOLIA]
})

import { createReducer } from '@reduxjs/toolkit'
import { setRecipient, typeInput } from './actions'
import { tryParseAmount } from './hooks'

export interface PoolState {
  readonly amounts: any
  readonly parsedAmounts: any
}

const initialState: PoolState = {
  amounts: {},
  parsedAmounts: {},
}

export default createReducer<PoolState>(initialState, builder =>
  builder
    .addCase(typeInput, (state, { payload: { typedIndex, typedValue, currency } }) => {
      return {
        ...state,
        amounts: {
          ...state.amounts,
          [typedIndex]: typedValue
        },
        parsedAmounts: {
          ...state.parsedAmounts,
          [typedIndex]: tryParseAmount(typedValue, currency ?? undefined)
        }
      }
    })
)


import { Currency, CurrencyAmount, TokenAmount, Token} from 'zksdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { formatUnits, parseUnits } from "@ethersproject/units"
import { Text } from 'rebass'
import { darken } from 'polished'
import numeral from 'numeral'
import { retry, RetryableError } from 'utils/retry'
import styled, { ThemeContext } from 'styled-components'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { selectList } from '../../state/lists/actions'
import { STABLE_TOKEN_LIST } from '../../constants/lists'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { LPTokenInputPanel } from '../../components/CurrencyInputPanel'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import Row from '../../components/Row'
import { DepositWithdrawTabs } from '../../components/NavigationTabs'
import { useStableSwapContract } from '../../hooks/useContract'
import { ChainId } from 'constants/chainId';
import { BETTER_TRADE_LINK_THRESHOLD, Stable_swap_contract_address } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { Dots } from './styleds'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import {
  usePoolActionHandlers,
  usePoolState
} from '../../state/pool/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { changeSymbol } from 'utils/index'
import { BigNumber } from 'ethers'


export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg2};
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
export default function Withdraw({
  match: {
    params: { poolId }
  },
  history
}: RouteComponentProps<{ poolId?: string }>) {
  const location = useLocation()
  const addTransaction = useTransactionAdder()
  const dispatch = useDispatch<AppDispatch>()
  const [lpTokenSymbol, setLpTokenSymbol] = useState<string>('')
  const [lpTokenName, setLpTokenName] = useState<string>('')
  const [currencyId0, setCurrencyId0] = useState<string>('')
  const [currencyId1, setCurrencyId1] = useState<string>('')
  const [currency0Balance, setCurrency0Balance] = useState<any>()
  const [currency1Balance, setCurrency1Balance] = useState<any>()
  const [currency0Position, setCurrency0Position] = useState<any>()
  const [currency1Position, setCurrency1Position] = useState<any>()
  const [totalSupply, setTotalSupply] = useState<any>()
  const [lpTokenBalance, setLpTokenBalance] = useState<any>()
  const [depositUrl, setDepositUrl] = useState<string>('')
  const [withdrawUrl, setWithdrawUrl] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const [parsedAmount, setParsedAmount] = useState<CurrencyAmount>()
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  const [withdrawInputError, setWithdrawInputError] = useState<string>('Enter an amount')
  const [refresh, setRefresh] = useState<number>(1)
  const { account, chainId, library } = useActiveWeb3React()

  // token warning stuff
  const [currency0, currency1] = [
    useCurrency(currencyId0),
    useCurrency(currencyId1)
  ]

  const StableSwap = useStableSwapContract(Stable_swap_contract_address[chainId || ChainId.BASE])

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const handleTypeInput = useCallback(
    (value: string) => {
      setAmount(value)
    },
    [chainId, poolId]
  )

  // modal and loading
  const [{ showStableConfirm, stableTradeToConfirm, swapStableErrorMessage, attemptingStableTxn, stableTxHash }, setStableSwapState] = useState<{
    showStableConfirm: boolean
    stableTradeToConfirm: any
    attemptingStableTxn: boolean
    swapStableErrorMessage: string | undefined
    stableTxHash: string | undefined
  }>({
    showStableConfirm: false,
    stableTradeToConfirm: undefined,
    attemptingStableTxn: false,
    swapStableErrorMessage: undefined,
    stableTxHash: undefined
  })

  // check whether the user has approved the router on the tokens
  const [approval, approveCallback] = useApproveCallback(parsedAmount, Stable_swap_contract_address[chainId || ChainId.BASE])
  const showStableApproveFlow =  
    !withdrawInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED))
  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(lpTokenBalance)
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))

  const stableWithdrawCallback = useCallback(async() => {
    let resTrade = new Promise((reject) => {
      reject({ message: 'RAIProxy is required.' });
    });
    if (StableSwap) {
      resTrade = await StableSwap.remove_liquidity(`0x${parsedAmount?.raw.toString(16)}`, [0, 0], account)
    }
    return resTrade
  },[account, StableSwap, parsedAmount])

  const handleStableWithdraw = useCallback(async() => {
    if (!stableWithdrawCallback) {
      return
    }
    setStableSwapState({ attemptingStableTxn: true, stableTradeToConfirm, showStableConfirm, swapStableErrorMessage: undefined, stableTxHash: undefined })
    stableWithdrawCallback()
    .then((res:any)  => {
      console.log(res)
      setStableSwapState({ attemptingStableTxn: false, stableTradeToConfirm, showStableConfirm, swapStableErrorMessage: undefined, stableTxHash: res.hash })
      const base = `Withdraw ${amount} ${lpTokenSymbol}`

      addTransaction(res, {
        summary: base
      })
      if (library){
        retry(() => {
          return library
          .getTransactionReceipt(res.hash)
          .then((receipt: any) => {
              if (receipt === null) {
                  console.debug('Retrying for hash', res.hash)
                  throw new RetryableError()
              }
              if (receipt) {
                  console.log("trade receipt", receipt)
                  setRefresh(refresh + 1)
              }
          })
      }, {
          n: Infinity,
          minWait: 2500,
          maxWait: 3500
      })
    }
    })
    .catch(error => {
      setStableSwapState({
        attemptingStableTxn: false,
        stableTradeToConfirm,
        showStableConfirm,
        swapStableErrorMessage: error.message,
        stableTxHash: undefined
      })
    })
  }, [
    stableTradeToConfirm, stableWithdrawCallback, stableTradeToConfirm, amount, lpTokenSymbol, library
  ])

  const handleMaxInput = useCallback(() => {
    maxAmountInput && setAmount(formatUnits(lpTokenBalance,18))
  }, [maxAmountInput, lpTokenBalance])

  useEffect(() => {
    if(location.pathname){
      setDepositUrl(location.pathname.replace('withdraw', 'deposit'))
      setWithdrawUrl(location.pathname.replace('deposit', 'withdraw'))
    }
  }, [location.pathname])

  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  useEffect(() => {
    dispatch(selectList(STABLE_TOKEN_LIST))
  },[])

  useEffect(() => {
    if(chainId && poolId && poolId && lpTokenSymbol && lpTokenName && amount){
      const token = new Token(chainId, poolId, 18, lpTokenSymbol, lpTokenName)
      const tokenAmount = new TokenAmount(token, parseUnits(amount, 18).toHexString())
      setParsedAmount(tokenAmount)
    }
  }, [poolId, chainId, poolId, lpTokenSymbol, lpTokenName, amount])

  useEffect(() => {
    if(StableSwap){
      StableSwap.symbol().then((res:any) => {
        setLpTokenSymbol(res)
      })
      StableSwap.name().then((res:any) => {
        setLpTokenName(res)
      })
      StableSwap.totalSupply().then((res:any) => {
        setTotalSupply(res)
      })
      StableSwap.coins(0).then((res:any) => {
        setCurrencyId0(res)
      })
      StableSwap.coins(1).then((res:any) => {
        setCurrencyId1(res)
      })
      StableSwap.balances(0).then((res: any) => {
        setCurrency0Balance(res)
      })
      StableSwap.balances(1).then((res: any) => {
        setCurrency1Balance(res)
      })
    }
    if(StableSwap && account){
      StableSwap.balanceOf(account).then((res: any) => {
        setLpTokenBalance(res)
      })
    }
  },[StableSwap, poolId])

  useEffect(() => {
    if(currency0Balance && lpTokenBalance && totalSupply){
      setCurrency0Position(lpTokenBalance.mul(currency0Balance).div(totalSupply))
    }
    if(currency1Balance && lpTokenBalance && totalSupply){
      setCurrency1Position(lpTokenBalance.mul(currency1Balance).div(totalSupply))
    }
  },[currency0Balance, currency1Balance, lpTokenBalance, totalSupply, amount])

  useEffect(() => {
    if(!amount && lpTokenSymbol){
      setWithdrawInputError('Enter an amount')
    }else if (lpTokenBalance && amount && lpTokenBalance.lt(parseUnits(amount, 18))) {
      setWithdrawInputError(`Insufficient ${lpTokenSymbol} balance`)
    }else {
      setWithdrawInputError('')
    }
  }, [amount, lpTokenSymbol, lpTokenBalance, amount])
  
  return (
    <>
      {/* <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      /> */}
      <AppBody>
        <Wrapper>
          <DepositWithdrawTabs active="withdraw" depositUrl={depositUrl} withdrawUrl={withdrawUrl}/>
          <Wrapper>
            <AutoColumn gap={'md'}>
              <LPTokenInputPanel
                label={lpTokenSymbol}
                value={amount}
                showMaxButton={!atMaxAmountInput}
                currency0={currency0}
                currency1={currency1}
                tokenBlance={lpTokenBalance}
                symbol={lpTokenSymbol}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                disableCurrencySelect
                id="deposit-currency-0"
              />
            </AutoColumn>
            <AutoColumn gap={'md'} style={{marginTop: '20px'}}>
              <RowBetween>
                <RowFixed>
                  <TYPE.black fontSize={14} fontWeight={400}>
                    {currency0 && 
                      <Row>
                          <CurrencyLogo currency={currency0} />
                          <Text fontWeight={500} fontSize={20} marginLeft={'6px'}>
                            {changeSymbol(chainId, currency0.symbol)}
                          </Text>
                      </Row>
                    }
                  </TYPE.black>
                </RowFixed>
                <RowFixed>
                  <TYPE.black fontSize={20}>
                    {currency0Position && amount && lpTokenBalance ? formatUnits(currency0Position.mul(parseUnits(amount, 18)).div(lpTokenBalance), currency0?.decimals) : '-'}
                  </TYPE.black>
                </RowFixed>
              </RowBetween>
              <RowBetween>
                <RowFixed>
                  <TYPE.black fontSize={14} fontWeight={400}>
                    {currency1 && 
                      <Row>
                          <CurrencyLogo currency={currency1} />
                          <Text fontWeight={500} fontSize={20} marginLeft={'6px'}>
                            {changeSymbol(chainId, currency1.symbol)}
                          </Text>
                      </Row>
                    }
                  </TYPE.black>
                </RowFixed>
                <RowFixed>
                  <TYPE.black fontSize={20}>
                    {currency1Position && amount && lpTokenBalance ? formatUnits(currency1Position.mul(parseUnits(amount, 18)).div(lpTokenBalance), currency1?.decimals) : '-'}
                  </TYPE.black>
                </RowFixed>
              </RowBetween>
            </AutoColumn>
            <BottomGrouping>
              {!account ? (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              ) : (
                <AutoColumn gap='12px'>
                  {showStableApproveFlow && (approval === ApprovalState.NOT_APPROVED ||
                    approval === ApprovalState.PENDING ||
                    (approvalSubmitted && approval === ApprovalState.APPROVED)) && (
                      <ButtonConfirmed
                        onClick={approveCallback}
                        disabled={approval === ApprovalState.PENDING}
                        mr="0.5rem"
                        fontWeight={500}
                        fontSize={16}
                      >
                        {approval === ApprovalState.PENDING ? (
                            <Dots>Approving</Dots>
                        ) : (
                            'Approve'
                        )}
                      </ButtonConfirmed>
                  )}
                  <ButtonError
                    onClick={() => {
                      handleStableWithdraw()
                    }}
                    // @ts-ignore
                    disabled={!!withdrawInputError || amount <= 0  || approval !== ApprovalState.APPROVED}
                    error={!!withdrawInputError && !!parsedAmount}
                  >
                    <Text fontSize={16} fontWeight={500}>
                      {withdrawInputError || 'Withdraw'}
                    </Text>
                  </ButtonError>
                </AutoColumn>
              )}
            </BottomGrouping>
          </Wrapper>
        </Wrapper>
      </AppBody>
      <AutoColumn style={{ minWidth: '22rem', marginTop: '1rem' }}>
        <GreyCard>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={16}>
                  Your position
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                {currency0 && currency1 && <DoubleCurrencyLogo margin={false} currency0={currency0} currency1={currency1} size={20} />}
                <Text fontWeight={500} fontSize={20}>
                  &nbsp;{lpTokenSymbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={20}>
                  {lpTokenBalance ? numeral(formatUnits(lpTokenBalance, 18)).format('0,0.000000') : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text color="#888D9B" fontSize={16} fontWeight={500}>
                  {currency0 && changeSymbol(chainId, currency0.symbol)}:
                </Text>
                {currency0Position ? (
                  <RowFixed>
                    <Text color="#888D9B" fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {currency0Position ? numeral(formatUnits(currency0Position, currency0?.decimals)).format('0,0.000000') : '-'}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text color="#888D9B" fontSize={16} fontWeight={500}>
                  {currency1 && changeSymbol(chainId, currency1.symbol)}:
                </Text>
                {currency1Position ? (
                  <RowFixed>
                    <Text color="#888D9B" fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {currency1Position ? numeral(formatUnits(currency1Position, currency1?.decimals)).format('0,0.000000') : '-'}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </GreyCard>
      </AutoColumn>
    </>
  )
}

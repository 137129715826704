import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index'
import EthereumLogo from '../../assets/images/ethereum-logo.png'

const BAD_IMAGES: any = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img<{ size: number }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div<{ size: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ name, header = false, size = '24px', ...rest }: any) {
  const path = `https://rai-static.s3.ap-northeast-1.amazonaws.com/sts/token/${name?.toLocaleLowerCase()}.png`

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          event.preventDefault()
        }}
      />
    </Inline>
  )
}

import { Currency, TokenAmount } from 'zksdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { retry, RetryableError } from 'utils/retry'
import styled, { ThemeContext } from 'styled-components'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { selectList } from '../../state/lists/actions'
import { STABLE_TOKEN_LIST } from '../../constants/lists'
import { useCurrencyBalances } from '../../state/wallet/hooks'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import TokenWarningModal from '../../components/TokenWarningModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import { DepositWithdrawTabs } from '../../components/NavigationTabs'
import { useStableSwapContract } from '../../hooks/useContract'
import { ChainId } from 'constants/chainId';
import { Stable_swap_contract_address } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { Dots } from './styleds'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import {
  usePoolActionHandlers,
  usePoolState
} from '../../state/pool/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { changeSymbol } from 'utils/index'


const SwapTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`
const SwapTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText1};
`

export default function Deposit({
  match: {
    params: { poolId }
  },
  history
}: RouteComponentProps<{ poolId?: string }>) {
  const location = useLocation()
  const addTransaction = useTransactionAdder()
  const dispatch = useDispatch<AppDispatch>()
  const [currencyId0, setCurrencyId0] = useState<string>('')
  const [currencyId1, setCurrencyId1] = useState<string>('')
  const [depositUrl, setDepositUrl] = useState<string>('')
  const [withdrawUrl, setWithdrawUrl] = useState<string>('')
  const [approval0Submitted, setApproval0Submitted] = useState<boolean>(false)
  const [approval1Submitted, setApproval1Submitted] = useState<boolean>(false)
  const [depositInputError, setDepositInputError] = useState<string>('Enter an amount')
  const [refresh, setRefresh] = useState<number>(1)
  const { account, chainId, library } = useActiveWeb3React()

  // token warning stuff
  const [currency0, currency1] = [
    useCurrency(currencyId0),
    useCurrency(currencyId1)
  ]

  const currencyBalances = useCurrencyBalances(account ?? undefined, [currency0 ?? undefined, currency1 ?? undefined])

  const StableSwap = useStableSwapContract(Stable_swap_contract_address[chainId || ChainId.BASE])

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // swap state
  const { amounts, parsedAmounts } = usePoolState()

  const { onUserInput } = usePoolActionHandlers()

  const handleTypeInput = useCallback(
    (index: number, value: string, currency: Currency) => {
      onUserInput(index, value, currency)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showStableConfirm, stableTradeToConfirm, swapStableErrorMessage, attemptingStableTxn, stableTxHash }, setStableSwapState] = useState<{
    showStableConfirm: boolean
    stableTradeToConfirm: any
    attemptingStableTxn: boolean
    swapStableErrorMessage: string | undefined
    stableTxHash: string | undefined
  }>({
    showStableConfirm: false,
    stableTradeToConfirm: undefined,
    attemptingStableTxn: false,
    swapStableErrorMessage: undefined,
    stableTxHash: undefined
  })

  // check whether the user has approved the router on the tokens
  const [approval0, approve0Callback] = useApproveCallback(parsedAmounts['0'], Stable_swap_contract_address[chainId || ChainId.BASE])
  const [approval1, approve1Callback] = useApproveCallback(parsedAmounts['1'], Stable_swap_contract_address[chainId || ChainId.BASE])
  const showStableApproveFlow =  
    !depositInputError &&
    ((approval0 === ApprovalState.NOT_APPROVED ||
      approval0 === ApprovalState.PENDING ||
      (approval0Submitted && approval0 === ApprovalState.APPROVED))
    ||(approval1 === ApprovalState.NOT_APPROVED ||
      approval1 === ApprovalState.PENDING ||
      (approval1Submitted && approval1 === ApprovalState.APPROVED))  
    )
      
  // get the max amounts user can add
  const maxAmounts: { [field in number]?: TokenAmount } = [0, 1].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
)

const atMaxAmounts: { [field in number]?: TokenAmount } = [0, 1].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      }
    },
    {}
)
  const stableDepositCallback = useCallback(async() => {
    let resTrade = new Promise((reject) => {
      reject({ message: 'RAIProxy is required.' });
    });
    if (StableSwap) {
      const amounts = [`0x${parsedAmounts['0']?.raw.toString(16)}`, `0x${parsedAmounts['1']?.raw.toString(16)}`]
      resTrade = await StableSwap.add_liquidity(amounts, 0, account)
    }
    return resTrade
  },[account, StableSwap, parsedAmounts])

  const handleStableDeposit = useCallback(async() => {
    if (!stableDepositCallback) {
      return
    }
    setStableSwapState({ attemptingStableTxn: true, stableTradeToConfirm, showStableConfirm, swapStableErrorMessage: undefined, stableTxHash: undefined })
    stableDepositCallback()
    .then((res:any)  => {
      console.log(res)
      setStableSwapState({ attemptingStableTxn: false, stableTradeToConfirm, showStableConfirm, swapStableErrorMessage: undefined, stableTxHash: res.hash })
      const base = `Deposit of ${amounts[0]} ${currency0?.symbol} ${amounts[1]} ${currency1?.symbol} `

      addTransaction(res, {
        summary: base
      })
      if (library){
        retry(() => {
          return library
          .getTransactionReceipt(res.hash)
          .then((receipt: any) => {
              if (receipt === null) {
                  console.debug('Retrying for hash', res.hash)
                  throw new RetryableError()
              }
              if (receipt) {
                  console.log("trade receipt", receipt)
                  setRefresh(refresh + 1)
              }
          })
      }, {
          n: Infinity,
          minWait: 2500,
          maxWait: 3500
      })
    }
  })
    .catch(error => {
      setStableSwapState({
        attemptingStableTxn: false,
        stableTradeToConfirm,
        showStableConfirm,
        swapStableErrorMessage: error.message,
        stableTxHash: undefined
      })
    })
  }, [
    stableTradeToConfirm, stableDepositCallback, stableTradeToConfirm, library
  ])

  const handleMaxInput = useCallback((index: number, value: string, currency: Currency) => {
    currency && onUserInput(index, value, currency)
  }, [onUserInput, parsedAmounts])

  useEffect(() => {
    if(location.pathname){
      setDepositUrl(location.pathname.replace('withdraw', 'deposit'))
      setWithdrawUrl(location.pathname.replace('deposit', 'withdraw'))
    }
  }, [location.pathname])

  useEffect(() => {
    if (approval0 === ApprovalState.PENDING) {
      setApproval0Submitted(true)
    }
  }, [approval0, approval0Submitted])

  useEffect(() => {
    if (approval1 === ApprovalState.PENDING) {
      setApproval1Submitted(true)
    }
  }, [approval1, approval0Submitted])

  useEffect(() => {
    dispatch(selectList(STABLE_TOKEN_LIST))
  },[])

  useEffect(() => {
    if(StableSwap){
      StableSwap.coins(0).then((res:any) => {
        setCurrencyId0(res)
      })
      StableSwap.coins(1).then((res:any) => {
        setCurrencyId1(res)
      })
    }
  },[StableSwap])

  useEffect(() => {
    if(!amounts[0] && !amounts[1]){
      setDepositInputError('Enter an amount')
    }else if (currencyBalances[0] && parsedAmounts['0'] && currencyBalances[0].lessThan(parsedAmounts['0'])) {
      setDepositInputError('Insufficient ' + parsedAmounts['0'].currency.symbol + ' balance')
    }else if (currencyBalances[1] && parsedAmounts['1'] && currencyBalances[1].lessThan(parsedAmounts['1'])) {
      setDepositInputError('Insufficient ' + parsedAmounts['1'].currency.symbol + ' balance')
    } else {
      setDepositInputError('')
    }
  }, [parsedAmounts, currencyBalances, parsedAmounts])
  
  return (
    <>
      {/* <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      /> */}
      <AppBody>
        <Wrapper>
          <DepositWithdrawTabs active="deposit" depositUrl={depositUrl} withdrawUrl={withdrawUrl}/>
          <Wrapper>
            <AutoColumn gap={'md'}>
              <CurrencyInputPanel
                label={'USDbC'}
                value={amounts[0]}
                showMaxButton={!atMaxAmounts[0]}
                currency={currency0}
                onUserInput={(value:string) => { currency0 && handleTypeInput(0, value, currency0)}}
                onMax={() => {currency0 && handleMaxInput(0, maxAmounts[0]?.toExact() ?? '',currency0)}}
                disableCurrencySelect
                id="deposit-currency-0"
              />
              <CurrencyInputPanel
                value={amounts[1]}
                onUserInput={(value:string) => {currency1 && handleTypeInput(1, value, currency1)}}
                onMax={() => {currency1 && handleMaxInput(1, maxAmounts[1]?.toExact() ?? '',currency1)}}
                label={'USDC'}
                showMaxButton={!atMaxAmounts[1]}
                currency={currency1}
                id="deposit-currency-1"
                disableCurrencySelect
              />
            </AutoColumn>
            <BottomGrouping>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
          ) : (
              <AutoColumn gap={'md'}>
                {showStableApproveFlow && (
                    <RowBetween>
                      {(approval0 === ApprovalState.NOT_APPROVED ||
                        approval0 === ApprovalState.PENDING ||
                        (approval0Submitted && approval0 === ApprovalState.APPROVED))&& (
                          <ButtonPrimary
                              onClick={approve0Callback}
                              disabled={approval0 === ApprovalState.PENDING}
                              width={approval1 === ApprovalState.NOT_APPROVED ? '48%' : '100%'}
                          >
                            {approval0 === ApprovalState.PENDING ? (
                                <Dots>Approving {changeSymbol(chainId, currency0?.symbol)}</Dots>
                            ) : (
                                'Approve ' + changeSymbol(chainId, currency0?.symbol)
                            )}
                          </ButtonPrimary>
                      )}
                      {(approval1 === ApprovalState.NOT_APPROVED ||
                        approval1 === ApprovalState.PENDING ||
                        (approval1Submitted && approval1 === ApprovalState.APPROVED))&& (
                          <ButtonPrimary
                              onClick={approve1Callback}
                              disabled={approval1 === ApprovalState.PENDING}
                              width={approval1 === ApprovalState.NOT_APPROVED ? '48%' : '100%'}
                          >
                            {approval1 === ApprovalState.PENDING ? (
                                <Dots>Approving {changeSymbol(chainId, currency1?.symbol)}</Dots>
                            ) : (
                                'Approve ' + changeSymbol(chainId, currency1?.symbol)
                            )}
                          </ButtonPrimary>
                      )}
                    </RowBetween>
                )}
                <ButtonError
                    onClick={handleStableDeposit}
                    disabled={!!depositInputError || approval0 !== ApprovalState.APPROVED || approval1 !== ApprovalState.APPROVED}
                    error={!!depositInputError && (!!parsedAmounts['0'] || !!parsedAmounts['1'])}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {depositInputError || 'Deposit'}
                  </Text>
                </ButtonError>
              </AutoColumn>
          )}
          </BottomGrouping>
          </Wrapper>
        </Wrapper>
      </AppBody>
    </>
  )
}

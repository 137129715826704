import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import Deposit from './Deposit'
import Withdraw from './Withdraw'

export function RedirectToWithdraw(props: RouteComponentProps<{ poolId: string }>) {
  const {
    match: {
      params: { poolId }
    }
  } = props
  return <Withdraw {...props} />
}

export function RedirectToDeposit(props: RouteComponentProps<{ poolId: string }>) {
  const {
    match: {
      params: { poolId }
    }
  } = props
  return <Deposit {...props} />
}

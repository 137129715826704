import { Interface } from '@ethersproject/abi'
import { ChainId } from '../chainId';
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  [ChainId.ROPSTEN]: '0x9c83dCE8CA20E9aAF9D3efc003b2ea62aBC08351',
  [ChainId.RINKEBY]: '0xf5D915570BC477f9B8D6C0E980aA81757A3AaC36',
  [ChainId.GÖRLI]: '0x6Ce570d02D73d4c384b46135E87f8C592A8c86dA',
  [ChainId.KOVAN]: '0xD3E51Ef092B2845f10401a0159B2B96e8B6c3D30',
  [ChainId.POLYGON]: '',
  [ChainId.BASE_GOERLI]: '',
  [ChainId.BASE]: '0xDA6b3B31177a07E157c170A31cF9C85e64f35d27',
  [ChainId.PZT]: '',
  [ChainId.PZ]: '',
  [ChainId.ZKSYNCTEST]: '0x71B5Fef83c4FdC19736F2F8e4c5Ac92B2CD56667',
  [ChainId.ZKSYNC]: '0x2e5cd8fB90ECc8C227feCE339F7027bB7d8c4424',
  [ChainId.LOOT]: '0x6668738E5913686FCD6C5211d489D8C47A9143E6',
  [ChainId.TAIKO]: '0x7D40C46CaD2C6Ade6440008f74dFb66b3FC947E4',
  [ChainId.BLAST_SEPOLIA]: '0xDA6b3B31177a07E157c170A31cF9C85e64f35d27'
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }

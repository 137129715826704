import React, { useState, useEffect } from 'react'
import { Text } from 'rebass'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import { Table } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import type { ColumnsType } from 'antd/es/table';
import { PlusCircle, Bookmark, AlertCircle } from 'react-feather'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useActiveWeb3React } from 'hooks'
import { ExternalLink } from 'theme/components'
import { ChainId } from 'constants/chainId'
import Link from 'components/Link'
import Panel from 'components/Panel'
import TokenLogo from 'components/TokenLogo'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import { AutoColumn } from 'components/Column'
import { ButtonLight, ButtonGray } from 'components/Button'
import TokenChart from 'components/TokenChart'
import { BasicLink } from 'components/Link'
import Warning from 'components/Warning'
import FormattedName from 'components/FormattedName'
import { formattedNum, shortenAddress, getEtherscanLink, localNumber } from 'utils'
// import { useTokenData, useTokenPairs } from '../contexts/TokenData'
import { TYPE, ThemedBackground } from 'theme/index'
// import { usePathDismissed, useSavedTokens } from '../contexts/LocalStorage'
// import { useListedTokens } from '../contexts/Application'
import { formattedPercent } from './index'
import { useTokenData } from './hooks'
import './index.less'

const DashboardWrapper = styled.div`
  width: 100%;
`
export const Hover = styled.div<{fade?: string}>`
  :hover {
    cursor: pointer;
    opacity: ${({ fade }) => fade && '0.7'};
  }
`
export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 80px;

  @media screen and (max-width: 600px) {
    & > * {
      padding: 0 12px;
    }
  }
`
export const ContentWrapper = styled.div`
  display: grid;
  justify-content: start;
  align-items: start;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 1180px) {
    grid-template-columns: 1fr;
  }
`
export const StyledIcon = styled.div`
  color: ${({ theme }) => theme.text1};
`
export const BlockedWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BlockedMessageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.text3};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  max-width: 80%;
`

const PanelWrapper = styled.div`
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 6px;
  display: inline-grid;
  width: 100%;
  align-items: start;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    > * {
      /* grid-column: 1 / 4; */
    }

    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
`


const WarningIcon = styled(AlertCircle)`
  stroke: ${({ theme }) => theme.text1};
  height: 16px;
  width: 16px;
  opacity: 0.6;
`

const WarningGrouping = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => disabled && '0.4'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

export default function({
    match: {
      params: { symbol }
    }
  }: RouteComponentProps<{ symbol: string }>) {
  const {
    id,
    address,
    name,
    price,
    liquidity,
    volume,
    price_change,
    price_history,
    transactions,
    txs_last_day,
  } = useTokenData(symbol)

  const { chainId } = useActiveWeb3React();

  const below1080 = useMedia('(max-width: 1080px)')
  const below800 = useMedia('(max-width: 800px)')
  const below600 = useMedia('(max-width: 600px)')
  const below500 = useMedia('(max-width: 500px)')

  // format for long symbol
  const LENGTH = below1080 ? 10 : 16
  const formattedSymbol = symbol?.length > LENGTH ? symbol.slice(0, LENGTH) + '...' : symbol

  // const [dismissed, markAsDismissed] = usePathDismissed(history.location.pathname)
  // const [savedTokens, addToken] = useSavedTokens()
  // const listedTokens = useListedTokens()

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    })
  }, [])

  const [useTracked, setUseTracked] = useState(true)

  const Columns: ColumnsType<any> = [
    {
      title: 'Swaps',
      dataIndex: 'symbol',
      key: 'symbol',
      width: '100px',
      fixed: 'left',
      render: (symbol: string, row: any) =>  {
        const [token0, token1] = symbol.split('-');
        return (
          <ExternalLink href={getEtherscanLink(chainId || ChainId.BASE, row.txid, 'transaction')}>{row.action}</ExternalLink>
        )}
    },
    {
      title: 'Total Value',
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value: string) => `US$${numeral(value).format('0,0.00')}`
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount_in',
      key: 'amount_in',
      width: 120,
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount_out',
      key: 'amount_out',
      width: 120,
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      width: 130,
      render: (account: string) => <ExternalLink href={getEtherscanLink(chainId || ChainId.BASE, account, 'address')}>{`${shortenAddress(account)}`}</ExternalLink>
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 140,
      render: (timestamp: number) => `${moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}`
    },
  ]

  return (
    <div className='tokenPage'>
      {/* <Warning
        type={'token'}
        show={!dismissed && listedTokens && !listedTokens.includes(address)}
        setShow={markAsDismissed}
        address={address}
      /> */}
      <ContentWrapper>
        <RowBetween style={{ flexWrap: 'wrap' }}>
          <AutoRow align="flex-end" style={{ width: 'fit-content' }}>
            <TYPE.body>
              <BasicLink to="/tokens">{'Tokens '}</BasicLink>→ {symbol}
            </TYPE.body>
            <ExternalLink href={getEtherscanLink(chainId || ChainId.BASE, address, 'address')}>
              <Text style={{ marginLeft: '.15rem' }} fontSize={'14px'} fontWeight={400}>
                ({address && address.slice(0, 8) + '...' + address.slice(36, 42)})
              </Text>
            </ExternalLink>
          </AutoRow>
        </RowBetween>
        <WarningGrouping >
          <DashboardWrapper style={{ marginTop: below1080 ? '0' : '1rem' }}>
            <RowBetween
              style={{
                flexWrap: 'wrap',
                marginBottom: '2rem',
                alignItems: 'flex-start',
              }}
            >
              <RowFixed style={{ flexWrap: 'wrap' }}>
                <RowFixed style={{ alignItems: 'baseline' }}>
                  <TokenLogo name={symbol} size="32px" style={{ alignSelf: 'center' }} />
                  <TYPE.main fontSize={below1080 ? '1.5rem' : '2rem'} fontWeight={500} style={{ margin: '0 1rem' }}>
                    <RowFixed gap="6px">
                      {/* <FormattedName text={name ? name + ' ' : ''} maxCharacters={16} style={{ marginRight: '6px' }} />{' '} */}
                      {formattedSymbol ? `(${formattedSymbol})` : ''}
                    </RowFixed>
                  </TYPE.main>{' '}
                  {!below1080 && (
                    <>
                      <TYPE.main fontSize={'1.5rem'} fontWeight={500} style={{ marginRight: '1rem' }}>
                        US${price}
                      </TYPE.main>
                      {price_change && formattedPercent(Number(price_change.replace('%', '')))}
                    </>
                  )}
                </RowFixed>
              </RowFixed>
              <span>
                {/* <RowFixed ml={below500 ? '0' : '2.5rem'} mt={below500 ? '1rem' : '0'}>
                  {!!!savedTokens[address] && !below800 ? (
                    <Hover onClick={() => addToken(address, symbol)}>
                      <StyledIcon>
                        <PlusCircle style={{ marginRight: '0.5rem' }} />
                      </StyledIcon>
                    </Hover>
                  ) : !below1080 ? (
                    <StyledIcon>
                      <Bookmark style={{ marginRight: '0.5rem', opacity: 0.4 }} />
                    </StyledIcon>
                  ) : (
                    <></>
                  )}
                  <Link href={getPoolLink(address)} target="_blank">
                    <ButtonLight>+ Add Liquidity</ButtonLight>
                  </Link>
                  <Link href={getSwapLink(address)} target="_blank">
                    <ButtonGray ml={'.5rem'}>
                      Trade
                    </ButtonGray>
                  </Link>
                </RowFixed> */}
              </span>
            </RowBetween>

            <>
              {!below1080 && (
                <RowFixed>
                  <TYPE.main fontSize={'1.125rem'} mr="6px">
                    Token Stats
                  </TYPE.main>
                </RowFixed>
              )}
              <PanelWrapper style={{ marginTop: below1080 ? '0' : '1rem' }}>
                {below1080 && price && (
                  <Panel>
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Price</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        {' '}
                        <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                          {price}
                        </TYPE.main>
                        <TYPE.main>{price_change}</TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                  </Panel>
                )}
                <Panel>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Total Liquidity</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                      US${formattedNum(liquidity)}
                      </TYPE.main>
                      <TYPE.main>{}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Volume (24hrs)</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                      US${formattedNum(volume)}
                      </TYPE.main>
                      <TYPE.main>{}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>

                <Panel>
                  <AutoColumn gap="20px">
                    <RowBetween>
                      <TYPE.main>Transactions (24hrs)</TYPE.main>
                      <div />
                    </RowBetween>
                    <RowBetween align="flex-end">
                      <TYPE.main fontSize={'1.5rem'} lineHeight={1} fontWeight={500}>
                        {txs_last_day ? localNumber(txs_last_day) : 0}
                      </TYPE.main>
                      <TYPE.main>{}</TYPE.main>
                    </RowBetween>
                  </AutoColumn>
                </Panel>
                <Panel
                  style={{
                    gridColumn: below1080 ? '1' : '2/4',
                    gridRow: below1080 ? '' : '1/4',
                  }}
                >
                  <TokenChart address={address} color={'#fff'} base={price} data={price_history} />
                </Panel>
              </PanelWrapper>
            </>
            <RowBetween mt={40} mb={'1rem'}>
              <TYPE.main fontSize={'1.125rem'}>Transactions</TYPE.main> <div />
            </RowBetween>
          </DashboardWrapper>
        </WarningGrouping>
      </ContentWrapper>
      <Panel>
        {transactions && 
          <Table 
            columns={Columns}
            dataSource={transactions}
            scroll={{ x: 300 }}
          />
        }
      </Panel>
    </div>
  )
}

import React from "react";
import { useHistory } from 'react-router-dom';
import RCFooter from 'rc-footer';
import styled from 'styled-components'
import { Tag } from 'antd';
import RAILogo from "assets/svg/logo.svg"
import {ReactComponent as TwitterLogo} from 'assets/svg/twitter.svg'
import {ReactComponent as MediumLogo} from 'assets/svg/medium.svg'
import {ReactComponent as TelegramLogo} from 'assets/svg/telegram.svg'
import './index.less'

const Logo = styled.img`
    width: 44px;
    height: auto;
    @media (max-width: 768px) {
        width: 30px;
    }
`

export default function Footer(){
    const history = useHistory();
    return (<RCFooter
        theme={"light"}
        columns={[
            {
                title: '',
                icon:(
                    <Logo className='footer-logo' src={RAILogo} onClick={() => {history.push('/')}}/>
                )
            },
            {
                title: 'Soswap',
                items: [
                    {
                        title: 'Trade',
                        url: '#/swap',
                        openExternal: false,
                    },
                    {
                        title: 'Pools',
                        url: '#/pool',
                        openExternal: false,
                    },
                    {
                        title: 'Farm',
                        url: '#/farm',
                        openExternal: false,
                    },
                    {
                        title: 'Analytics',
                        url: '#/analytics',
                        openExternal: false,
                    },
                ],
            },
            {
                title: <>Soswap Pro&nbsp;<Tag color="green">Beta</Tag></>,
                items: [
                    {
                        title: 'Lite Swap',
                        url: 'https://soswappro.rai.finance/#/swap',
                        openExternal: false,
                    },
                    {
                        title: 'Limit Order',
                        url: 'https://soswappro.rai.finance/#/limit',
                        openExternal: false,
                    },
                    {
                        title: 'Pro Trading',
                        url: 'https://soswappro.rai.finance/#/pro',
                        openExternal: false,
                    },
                    {
                        title: 'Pools',
                        url: 'https://soswappro.rai.finance/#/pools',
                        openExternal: false,
                    },
                    {
                        title: 'Analytics',
                        url: 'https://analytics.rai.finance/#/Dashboard',
                        openExternal: false,
                    },
                ],
            },
            {
                title: <>NFT&nbsp;<Tag color="blue">coming soon</Tag></>,
                items: [
                    {
                        title: 'Dashboard',
                        url: 'javascript:void(0)',
                        openExternal: false,
                    },
                    {
                        title: 'Lend',
                        url: 'javascript:void(0)',
                        openExternal: false,
                    },
                    {
                        title: 'Farm',
                        url: 'javascript:void(0)',
                        openExternal: false,
                    },
                    {
                        title: 'Auction',
                        url: 'javascript:void(0)',
                        openExternal: false,
                    },
                ],
            },
            {
                title: 'More Products',
                items: [
                    {
                        title: 'Social Trading',
                        url: 'https://app.rai.finance/#/sts',
                        openExternal: false,
                    },
                    {
                        title: 'Analytics',
                        url: 'javascript:void(0)',
                        openExternal: false,
                    },
                ],
            },
            {
                title: 'More',
                items: [
                    {
                        title: 'Governance',
                        url: 'https://app.rai.finance/#/proposals',
                        openExternal: false,
                    },
                    {
                        title: 'Whitepaper',
                        url: 'https://rai.finance/RAI-Finance-WhitePaper.pdf',
                        openExternal: false,
                    },
                    {
                        title: 'Wiki',
                        url: 'https://rai-finance.gitbook.io/rai-finance/',
                        openExternal: true,
                    }
                ],
            },
        ]}
        bottom={
            <div className='footer_bottom'>
                <div>{`Copyrights © ${new Date().getFullYear()} RAI.Finance All rights reserved.`}</div>
                <div>  
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/RaiFinance">
                        <TwitterLogo/> 
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://medium.com/rai-finance">
                        <MediumLogo/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://t.me/RaiFinance">
                        <TelegramLogo/>
                    </a>
                </div>
            </div>
        }
    />)
}
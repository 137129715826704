import { CurrencyAmount, JSBI, Token, Trade } from 'zksdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { AppDispatch } from '../../state'
import { selectList } from '../../state/lists/actions'
import { STABLE_TOKEN_LIST } from '../../constants/lists'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import StableConfirmSwapModal from '../../components/swap/StableConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapTabs } from '../../components/NavigationTabs'
import BetterTradeLink from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import ProgressSteps from '../../components/ProgressSteps'
import { useStableSwapContract } from '../../hooks/useContract'
import { ChainId } from 'constants/chainId';
import { Stable_swap_contract_address } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import {
  useStableDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { usePairIsInAggregator } from 'state/lists/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import Settings from '../../components/Settings'
import { formatUnits } from 'ethers/lib/utils'

const SwapTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`
const SwapTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText1};
`

export default function Swap() {
  const loadedUrlParams = useStableDefaultsFromURLSearch()
  const addTransaction = useTransactionAdder()
  const dispatch = useDispatch<AppDispatch>()
  const [currencyId0, setCurrencyId0] = useState<string>('')
  const [currencyId1, setCurrencyId1] = useState<string>('')
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const { account, chainId } = useActiveWeb3React()
  const StableSwap = useStableSwapContract(Stable_swap_contract_address[chainId || ChainId.BASE])

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    stableTrade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo(true)
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : (stableTrade?.inputAmount),
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : (stableTrade?.outputAmount)
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )
  // modal and loading
  const [{ showStableConfirm, stableTradeToConfirm, swapStableErrorMessage, attemptingStableTxn, stableTxHash }, setStableSwapState] = useState<{
    showStableConfirm: boolean
    stableTradeToConfirm: any
    attemptingStableTxn: boolean
    swapStableErrorMessage: string | undefined
    stableTxHash: string | undefined
  }>({
    showStableConfirm: false,
    stableTradeToConfirm: undefined,
    attemptingStableTxn: false,
    swapStableErrorMessage: undefined,
    stableTxHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  // check whether the user has approved the router on the input token
  const [stableApproval, stableApprovalCallback] = useApproveCallback(parsedAmounts[independentField], Stable_swap_contract_address[chainId || ChainId.BASE])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [stableApprovalSubmitted, setStableApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (stableApproval === ApprovalState.PENDING) {
      setStableApprovalSubmitted(true)
    }
  }, [stableApproval, stableApprovalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  const stableSwapCallback = useCallback(async() => {
    let resTrade = new Promise((reject) => {
      reject({ message: 'RAIProxy is required.' });
    });
    if (StableSwap) {
      // const minAmountOutput = parsedAmounts[independentField].mul((1000 - allowedSlippage)/ 1000)
      //@ts-ignore
      resTrade = await StableSwap.exchange(currencies[Field.INPUT].address === currencyId0 ? 0 : 1, currencies[Field.INPUT].address === currencyId0 ? 1 : 0, `0x${parsedAmounts[independentField]?.raw.toString(16)}`, 0, account)
    }
    return resTrade
  },[stableTrade, currencies, parsedAmounts, account, StableSwap, currencyId0, currencyId1])

  const handleStableSwap = useCallback(async() => {
    if (!stableSwapCallback) {
      return
    }
    setStableSwapState({ attemptingStableTxn: true, stableTradeToConfirm, showStableConfirm, swapStableErrorMessage: undefined, stableTxHash: undefined })
    stableSwapCallback()
    .then((res:any)  => {
      console.log(res)
      // const hash = "0x8977f11e0db2b90570fc74593f41d773792d9f8858668cca4e003bd299d36626"
      setStableSwapState({ attemptingStableTxn: false, stableTradeToConfirm, showStableConfirm, swapStableErrorMessage: undefined, stableTxHash: res.hash })
      const inputSymbol = currencies[Field.INPUT]?.symbol
      const outputSymbol = currencies[Field.OUTPUT]?.symbol
      const inputAmount = stableTrade.inputAmount.toSignificant(3)
      const outputAmount = stableTrade.outputAmount.toSignificant(3)

      const base = `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`

      addTransaction(res, {
        summary: base
      })
    })
    .catch(error => {
      setStableSwapState({
        attemptingStableTxn: false,
        stableTradeToConfirm,
        showStableConfirm,
        swapStableErrorMessage: error.message,
        stableTxHash: undefined
      })
    })
  }, [
    currencies, stableTrade, stableTradeToConfirm, stableSwapCallback, stableTradeToConfirm, 
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mod
  const showStableApproveFlow =  
    !swapInputError &&
    (stableApproval === ApprovalState.NOT_APPROVED ||
      stableApproval === ApprovalState.PENDING ||
      (stableApprovalSubmitted && stableApproval === ApprovalState.APPROVED))

  const handleStableConfirmDismiss = useCallback(() => {
    setStableSwapState({ showStableConfirm: false, stableTradeToConfirm, attemptingStableTxn, swapStableErrorMessage, stableTxHash })
    // if there was a tx hash, we want to clear the input
    if (stableTxHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingStableTxn, onUserInput, swapStableErrorMessage, stableTradeToConfirm, stableTxHash])

  const handleStableAcceptChanges = useCallback(() => {
    setStableSwapState({ stableTradeToConfirm: stableTrade, swapStableErrorMessage, stableTxHash, attemptingStableTxn, showStableConfirm })
  }, [attemptingStableTxn, showStableConfirm, swapStableErrorMessage, stableTrade, stableTxHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setStableApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, currencies[Field.INPUT]?.symbol !== 'SOFI' ? maxAmountInput.toExact() : Math.floor(Number(maxAmountInput.toExact())).toString())
  }, [maxAmountInput, onUserInput, currencies[Field.INPUT]])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  useEffect(() => {
    dispatch(selectList(STABLE_TOKEN_LIST))
  },[])

  useEffect(() => {
    if(StableSwap){
      StableSwap.coins(0).then((res:any) => {
        setCurrencyId0(res)
      })
      StableSwap.coins(1).then((res:any) => {
        setCurrencyId1(res)
      })
    }
  },[StableSwap])

  return (
    <>
      {/* <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      /> */}
      <AppBody>
        <SwapTabs active={'stableSwap'} />
        <Settings />
        <Wrapper>
            <Wrapper id="swap-page">
              <StableConfirmSwapModal
                isOpen={showStableConfirm}
                trade={stableTrade}
                originalTrade={stableTradeToConfirm}
                onAcceptChanges={handleStableAcceptChanges}
                attemptingTxn={attemptingStableTxn}
                txHash={stableTxHash}
                onConfirm={handleStableSwap}
                swapErrorMessage={swapStableErrorMessage}
                onDismiss={handleStableConfirmDismiss}
              />

              <AutoColumn gap={'md'}>
                <CurrencyInputPanel
                  label={independentField === Field.OUTPUT && !showWrap ? 'From (estimated)' : 'From'}
                  value={formattedAmounts[Field.INPUT]}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id="swap-currency-input"
                />
                <AutoColumn justify="space-between">
                  <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable>
                      <ArrowDown
                        size="16"
                        onClick={() => {
                          setStableApprovalSubmitted(false)
                          onSwitchTokens()
                        }}
                        color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.text1 : theme.text2}
                      />
                    </ArrowWrapper>
                    {recipient === null && !showWrap && isExpertMode ? (
                      <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                        + Add a send (optional)
                      </LinkStyledButton>
                    ) : null}
                  </AutoRow>
                </AutoColumn>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={independentField === Field.INPUT && !showWrap ? 'To (estimated)' : 'To'}
                  showMaxButton={false}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  id="swap-currency-output"
                />

                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.text2} />
                      </ArrowWrapper>
                      <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                        - Remove send
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                  </>
                ) : null}
              </AutoColumn>
              <BottomGrouping>
                {!account ? (
                  <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                ) : showWrap ? (
                  <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                  </ButtonPrimary>
                ) : !stableTrade?.route && userHasSpecifiedInputOutput ? (
                  <GreyCard style={{ textAlign: 'center' }}>
                    <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
                  </GreyCard>
                ) : 
                (showStableApproveFlow ? (
                  <RowBetween>
                    <ButtonConfirmed
                      onClick={stableApprovalCallback}
                      disabled={stableApproval !== ApprovalState.NOT_APPROVED || stableApprovalSubmitted}
                      width="48%"
                      altDisabledStyle={stableApproval === ApprovalState.PENDING} // show solid button while waiting
                      confirmed={stableApproval === ApprovalState.APPROVED}
                    >
                      {stableApproval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          Approving <Loader stroke="white" />
                        </AutoRow>
                      ) : stableApprovalSubmitted && stableApproval === ApprovalState.APPROVED ? (
                        'Approved'
                      ) : (
                        'Approve ' + currencies[Field.INPUT]?.symbol
                      )}
                    </ButtonConfirmed>
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handleStableSwap()
                        } else {
                          setStableSwapState({
                            stableTradeToConfirm: stableTrade,
                            attemptingStableTxn: false,
                            swapStableErrorMessage: undefined,
                            showStableConfirm: true,
                            stableTxHash: undefined
                          })
                        }
                      }}
                      width="48%"
                      id="swap-button"
                      disabled={
                        !isValid || stableApproval !== ApprovalState.APPROVED
                      }
                      // error={isValid}
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {`Swap`}
                      </Text>
                    </ButtonError>
                  </RowBetween>
                ) : (
                  <ButtonError
                    onClick={() => {
                      if (isExpertMode) {
                        handleStableSwap()
                      } else {
                        setStableSwapState({
                          stableTradeToConfirm: stableTrade,
                          attemptingStableTxn: false,
                          swapStableErrorMessage: undefined,
                          showStableConfirm: true,
                          stableTxHash: undefined
                        })
                      }
                    }}
                    id="swap-button"
                    disabled={!isValid}
                    // error={isValid}
                  >
                    <Text fontSize={20} fontWeight={500}>
                      {swapInputError
                        ? swapInputError
                        : `Swap`}
                    </Text>
                  </ButtonError>
                ))
                }
                {showStableApproveFlow && <ProgressSteps steps={[stableApproval === ApprovalState.APPROVED]} />}
              </BottomGrouping>
            </Wrapper>
        </Wrapper>
      </AppBody>
    </>
  )
}

import { Contract } from '@ethersproject/contracts'
import { ChainId } from 'zksdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Web3Provider, JsonRpcSigner } from '@ethersproject/providers';
import { useMemo } from 'react'
import { WETH, PRED } from 'constants/index'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import { V1_EXCHANGE_ABI, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1'
import { RAI_PROXY } from '../constants/abis/rai-proxy'
import { SWAP_ABI } from 'constants/abis/swap';
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import { FixedProductMarketMaker_ABI } from '../constants/abis/FixedProductMarketMaker'
import FixedProductMarketMakerService from 'services/fixed_product_market_maker'
import CONDITIONAL_TOKENS_ABI from '../constants/abis/ConditionalTokens.json';
import ConditionalTokensService from 'services/conditional_token';

// returns null on errors
function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true,
  connectSigner = false,
): T | null {
  const { library, account, chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      const contract = getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      );
      if (connectSigner) {
        const signer = getSigner(library, account as string);
        return contract.connect(signer);
      }
      return contract;
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T;
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

export function useV1FactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && V1_FACTORY_ADDRESSES[chainId], V1_FACTORY_ABI, false)
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
//      case ChainId.MAINNET:
//      case ChainId.GÖRLI:
//      case ChainId.ROPSTEN:
//      case ChainId.RINKEBY:
      case ChainId.ZKSYNCTEST:
//      case ChainId.ZKSYNC:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    undefined,
    UNISOCKS_ABI,
    false
  )
}

export function useFpmmInterface(marketAddress: string, provider: Web3Provider, account: string) {
  const {chainId } = useActiveWeb3React()
  const contract = useContract<Contract>(
    marketAddress,
    FixedProductMarketMaker_ABI,
    true,
    Boolean(account),
  );
  return useMemo(
    () => contract && account && new FixedProductMarketMakerService(contract, chainId ? PRED[chainId].address : '', provider, account),
    [contract, account],
  );
}

export function useConditionalTokenInterface(provider: Web3Provider, address: string) {
  const contract = useContract<Contract>(address, CONDITIONAL_TOKENS_ABI, false);
  return useMemo(() => contract && new ConditionalTokensService(provider, contract), [contract]);
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useRAIProxyContract(proxyAddress?: string): Contract | null {
  return useContract(proxyAddress, RAI_PROXY, true)
}

export function useStableSwapContract(swapAddress?: string): Contract | null{
  return useContract(swapAddress, SWAP_ABI, true)
}
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from "@ethersproject/units"
import {
  Currency,
  CurrencyAmount,
  JSBI,
} from 'zksdk'
import { Stable_swap_contract_address } from 'constants/index'
import { ChainId } from 'constants/chainId'
import { useMemo, useState } from 'react'
import {  useStableSwapContract } from '../hooks/useContract'
import { useActiveWeb3React } from '../hooks'
import { tryParseAmount } from '../state/swap/hooks'
import { usePairIsInAggregator } from 'state/lists/hooks'
/**
 * Returns the trade to execute on V1 to go between input and output token
 */
export function useStableTrade(
  isStableSwap?: boolean,
  inputCurrency?: Currency,
  outputCurrency?: Currency,
  exactAmount?: CurrencyAmount,
  allowedSlippage?: number
): any | undefined {
  const { chainId } = useActiveWeb3React()
  const StableSwap = useStableSwapContract(Stable_swap_contract_address[chainId || ChainId.BASE])
  const userHasSpecifiedInputOutput = Boolean(
    inputCurrency && outputCurrency && exactAmount?.greaterThan(JSBI.BigInt(0))
  )
  const [route, setRoute] = useState<boolean>(false)
  const [outputAmount, setOutputAmount] = useState<CurrencyAmount>()
  useMemo(() => {
    if (isStableSwap && StableSwap && userHasSpecifiedInputOutput && allowedSlippage) {
        StableSwap
      .get_dy(
        0,
        1,
        `0x${exactAmount?.raw.toString(16)}`,
      ).then((res: any) => {
        setRoute(true)
        const num = res
        const outputParsedAmount = tryParseAmount(formatUnits(num, outputCurrency?.decimals), outputCurrency)
        setOutputAmount(outputParsedAmount)
      })
    }else{
      setRoute(false)
    }
  }, [isStableSwap, StableSwap, userHasSpecifiedInputOutput, exactAmount?.toExact(), allowedSlippage])

  return useMemo(() => {
    let trade: any
    try {
      trade =
        route && exactAmount
          ? {
            route,
            inputAmount: exactAmount,
            outputAmount,
          }
          : undefined
    } catch (error) {
      console.debug('Failed to create V1 trade', error)
    }
    return trade
  },[route, outputAmount])
  // const route = inputCurrency && pairs && pairs.length > 0 && new Route(pairs, inputCurrency, outputCurrency)

}
